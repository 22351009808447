import LazyHydration from 'components/LazyHydration';

import GooglePublisherTagLandscapeAdSlot from '.';

const LazyHydratedGooglePublisherTagLandscapeAdSlot = (props) => (
  <LazyHydration placeholderSize={{ height: 90 }}>
    <GooglePublisherTagLandscapeAdSlot {...props} />
  </LazyHydration>
);

export default LazyHydratedGooglePublisherTagLandscapeAdSlot;
