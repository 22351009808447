import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';

const Card = ({ className, ...rest }) => (
  <Box
    className={twMerge(
      'md:justify-between lg:hover:shadow-md lg:hover:transition-all lg:hover:ease-in',
      className
    )}
    {...rest}
  />
);

export default memo(Card);
