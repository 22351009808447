// TODO: Fix bug <Box /> is being styled when `adSlot` isn't rendered
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';

import GooglePublisherTagAdSlot from 'components/AdSlots/GooglePublisherTagAdSlot';

const GooglePublisherTagLandscapeAdSlot = ({
  adId,
  adPath,
  adSizes = [
    [320, 50],
    [728, 90],
  ],
  adResponsiveSizesMap = [
    {
      viewport: [768, 0],
      sizesMap: [728, 90],
    },
    {
      viewport: [0, 0],
      sizesMap: [320, 50],
    },
  ],
  width,
  display = 'block',
  showAdSlotOnBreakpoints = [],
  className = 'h-[50px] md:h-[90px] [&>div]:h-[50px] [&>div]:md:h-[90px]',
}) => {
  const adSlotRef = useRef(null);
  const [displayStyle, setDisplayStyle] = useState(display);

  useEffect(() => {
    const { isPublisherTagAdSlotActive } = adSlotRef.current;

    if (isPublisherTagAdSlotActive) {
      setDisplayStyle(isPublisherTagAdSlotActive ? display : 'hidden');
    }
  }, [adSlotRef.current?.isPublisherTagAdSlotActive, display]);

  return (
    <Box className={twMerge('z-0', displayStyle, className)} full>
      <GooglePublisherTagAdSlot
        ref={adSlotRef}
        adId={adId}
        adPath={adPath}
        adResponsiveSizesMap={adResponsiveSizesMap}
        adSizes={adSizes}
        className={twJoin(
          'flex justify-center',
          width ? 'w-[var(--googlePublisherWidth)]' : 'w-full'
        )}
        showAdSlotOnBreakpoints={showAdSlotOnBreakpoints}
        style={{ '--googlePublisherWidth': width }}
      />
    </Box>
  );
};

GooglePublisherTagLandscapeAdSlot.propTypes = {
  adId: PropTypes.string.isRequired,
  adPath: PropTypes.string.isRequired,
  adSizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ),
  adResponsiveSizesMap: PropTypes.arrayOf(
    PropTypes.shape({
      viewport: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.number),
        ])
      ).isRequired,
      sizesMap: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.number),
        ])
      ).isRequired,
    })
  ),
  display: PropTypes.string,
  showAdSlotOnBreakpoints: PropTypes.array,
};

export default GooglePublisherTagLandscapeAdSlot;
