/**
 * See: https://developers.google.com/publisher-tag/reference for
 * general API reference.
 */
import Script from 'next/script';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import useMediaQuery from 'hooks/useMediaQuery';

import { getFromLocalStorage } from 'lib/localStorage';
import { parseToBool } from 'lib/string';

import LOCAL_STORAGE from 'constants/localStorage';

const isUserAllowedToReceivePersonalizedAds = parseToBool(
  getFromLocalStorage(LOCAL_STORAGE.IS_POLICY_NUMBER_3_ACCEPTED)
);

const AD_MANAGER_NETWORK_CODE = 21977757450;
const INTERVAL_TIME_IN_MILLISECONDS = 600;
const INTERVAL_TIME_RUNS_LIMIT_TO_TIMEOUT = 20;

const toCompleteSlotPath = (adPath) => `/${AD_MANAGER_NETWORK_CODE}${adPath}`;

const isValidBreakpointValue = (value) => value !== null && value !== undefined;

const defineResponsiveSlot = ({
  adId,
  adSizes,
  adResponsiveSizesMap,
  adPath,
  adSlotRef,
}) => {
  window.googletag.cmd.push(() => {
    adSlotRef.current = window.googletag
      .defineSlot(toCompleteSlotPath(adPath), adSizes, adId)
      .addService(window.googletag.pubads());

    if (adResponsiveSizesMap.length > 0) {
      const sizeMappingBuilder = window.googletag.sizeMapping();

      adResponsiveSizesMap.forEach(({ viewport, sizesMap }) => {
        sizeMappingBuilder.addSize(viewport, sizesMap);
      });

      const mapping = sizeMappingBuilder.build();

      adSlotRef.current.defineSizeMapping(mapping);
    }

    window.googletag.enableServices();
  });
};

const destroySlot = (adSlot) => {
  window.googletag.cmd.push(() => {
    window.googletag.destroySlots([adSlot]);
  });
};

const GooglePublisherTagAdSlot = forwardRef(
  (
    {
      adId,
      adPath,
      adResponsiveSizesMap = [],
      adSizes,
      className,
      showAdSlotOnBreakpoints = [],
      ...rest
    },
    ref
  ) => {
    const adSlotRef = useRef(null);
    const { isSm, isMd, isLg, isXl } = useMediaQuery();
    const [isReadyToStartLoadPubAdsService, setReadyToStartLoadPubAdsService] =
      useState(false);
    const [isPubAdsServiceLoaded, setPubAdsServiceLoaded] = useState(false);
    const [isPublisherTagAdSlotActive, setPublisherTagAdSlotActive] =
      useState(false);

    /**
     * Expose relevant states to ref
     */
    useImperativeHandle(
      ref,
      () => ({
        isPublisherTagAdSlotActive,
      }),
      [isPublisherTagAdSlotActive]
    );

    /**
     * Validate `showAdSlotOnBreakpoints` before enabling the ad be the shown
     */
    useEffect(() => {
      if (showAdSlotOnBreakpoints.length === 0) {
        setPublisherTagAdSlotActive(true);
      }

      const breakpoints = [isSm, isMd, isLg, isXl];
      const currBreakpointIndex = breakpoints.lastIndexOf(true);

      if (showAdSlotOnBreakpoints.length === breakpoints.length) {
        const currBreakpointValue =
          showAdSlotOnBreakpoints[currBreakpointIndex];

        if (currBreakpointValue !== null) {
          setPublisherTagAdSlotActive(currBreakpointValue);
          return;
        }
      }

      for (
        let index = showAdSlotOnBreakpoints.length - 1;
        index >= 0;
        index--
      ) {
        if (index > currBreakpointIndex) {
          continue;
        }

        if (index === 0) {
          setPublisherTagAdSlotActive(true);
          break;
        }

        if (isValidBreakpointValue(showAdSlotOnBreakpoints[index])) {
          setPublisherTagAdSlotActive(showAdSlotOnBreakpoints[index]);
          break;
        }
      }
    }, []);

    /**
     * Set `pubAdsServiceReady` when the service is correctly loaded
     */
    useEffect(() => {
      if (!isReadyToStartLoadPubAdsService) {
        return;
      }

      if (window.googletag?.pubadsReady) {
        setPubAdsServiceLoaded(true);
        return;
      }

      let intervalRunIndex = 0;

      const isGoogleTagApiReadyIntervalId = setInterval(() => {
        if (window.googletag?.pubadsReady) {
          setPubAdsServiceLoaded(true);
          clearInterval(isGoogleTagApiReadyIntervalId);
        }

        intervalRunIndex += 1;

        if (intervalRunIndex === INTERVAL_TIME_RUNS_LIMIT_TO_TIMEOUT) {
          clearInterval(isGoogleTagApiReadyIntervalId);
        }
      }, INTERVAL_TIME_IN_MILLISECONDS);

      return () => {
        clearInterval(isGoogleTagApiReadyIntervalId);
      };
    }, [isReadyToStartLoadPubAdsService]);

    /**
     * Defines and subsequently destroys a slot
     */
    useEffect(() => {
      window.googletag = window.googletag || { cmd: [] };

      if (!window.googletag.cmd) {
        window.googletag.cmd = [];
      }

      defineResponsiveSlot({
        adId,
        adPath,
        adResponsiveSizesMap,
        adSizes,
        adSlotRef,
      });

      setReadyToStartLoadPubAdsService(true);

      return () => {
        destroySlot(adSlotRef.current);
      };
    }, []);

    /**
     * Displays an ad after service on `isPubAdsServiceLoaded` change
     */
    useEffect(() => {
      if (!isPubAdsServiceLoaded) {
        return;
      }

      window.googletag.cmd.push(() => {
        window.googletag.display(adId);
      });
    }, [isPubAdsServiceLoaded]);

    if (!isPublisherTagAdSlotActive) {
      return null;
    }

    return (
      <>
        <Script
          id="google-ad-manager"
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        />
        <div
          className={twMerge('flex overflow-hidden', className)}
          id={adId}
          {...rest}
        />
        {/* Will be executed only once at the first
          `GooglePublisherTagAdSlot` rendered, setting some PubAds service
          configs */}
        <Script id="google-ad-manager-script-initial-config">
          {`
            window.googletag = window.googletag || {cmd: []};

            if (!window.googletag.cmd) {
              window.googletag.cmd = [];
            }

            window.googletag.cmd.push(function() {
              googletag.pubads().setPrivacySettings({
                nonPersonalizedAds: ${isUserAllowedToReceivePersonalizedAds}
              })
              googletag.pubads().enableSingleRequest();
            });
          `}
        </Script>
      </>
    );
  }
);

GooglePublisherTagAdSlot.displayName = 'GooglePublisherTagAdSlot';

GooglePublisherTagAdSlot.propTypes = {
  adId: PropTypes.string.isRequired,
  /**
   * Reference: https://developers.google.com/publisher-tag/guides/get-started#ad-unit-path
   */
  adPath: PropTypes.string.isRequired,
  /**
   * Type reference:
   * - https://developers.google.com/publisher-tag/reference#googletag.GeneralSize
   */
  adSizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ).isRequired,
  /**
   * Type references:
   * - https://developers.google.com/publisher-tag/reference#googletag.SingleSizeArray
   * - https://developers.google.com/publisher-tag/reference#googletag.GeneralSize
   */
  adResponsiveSizesMap: PropTypes.arrayOf(
    PropTypes.shape({
      viewport: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.number),
        ])
      ).isRequired,
      sizesMap: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.number),
        ])
      ).isRequired,
    })
  ),
  /**
   * Set-up breakpoints where the ads are loaded or not.
   * The breakpoint order are: [sm, md, lg, xl]
   */
  showAdSlotOnBreakpoints: PropTypes.array,
};

export default GooglePublisherTagAdSlot;
