/**
 *  TODO `onSuccess` and `onError` are deprecated but we have to create our own
 * solution
 */

/**
 * Wraps tanstack's `useInfiniteQuery` and resolves some DX issues, like
 * flattening all pages by a specific object key and normalize `data` type, to
 * be always an array.
 */
import { useInfiniteQuery as useTanstackInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

const useInfiniteQuery = ({
  cacheTime,
  flattenKey,
  initialData,
  placeholderData,
  queryFn,
  queryKey,
  refetchInterval,
  refetchIntervalInBackground,
  refetchOnMount,
  refetchOnReconnect,
  refetchOnWindowFocus,
  select,
  staleTime,
  useErrorBoundary,
  enabled,
  getNextPageParam,
  getPreviousPageParam,
  onSuccess,
  onError,
}) => {
  const {
    data,
    dataUpdatedAt,
    error,
    errorUpdatedAt,
    failureCount,
    isError,
    isFetched,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isLoadingError,
    isPlaceholderData,
    isPreviousData,
    isRefetchError,
    isRefetching,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
    fetchNextPage,
    isFetchingNextPage,
    isInitialLoading,
    hasNextPage,
  } = useTanstackInfiniteQuery({
    queryKey,
    queryFn: async (params) =>
      queryFn(params.pageParam, { signal: params.signal }),
    getNextPageParam,
    getPreviousPageParam,
    onSuccess,
    onError,
    initialData,
    enabled,
    cacheTime,
    placeholderData,
    refetchInterval,
    refetchOnMount,
    refetchIntervalInBackground,
    refetchOnReconnect,
    refetchOnWindowFocus,
    staleTime,
    useErrorBoundary,
  });

  // if `flattenKey` is falsy, consider `page` as an array
  const extractByFlattenKey = useCallback(
    (page) => (flattenKey ? page[flattenKey] : page),
    [flattenKey]
  );

  const selectedData = useMemo(() => {
    if (!data) {
      return [];
    }

    if (isInitialLoading) {
      if (typeof select === 'function') {
        return select(extractByFlattenKey(initialData));
      }

      return extractByFlattenKey(initialData);
    }

    const normalized =
      'pages' in data
        ? data.pages.flatMap(extractByFlattenKey)
        : extractByFlattenKey(data);

    if (typeof select === 'function') {
      return select(normalized);
    }

    return normalized;
  }, [data, isInitialLoading, extractByFlattenKey]);

  return {
    data: selectedData,
    dataUpdatedAt,
    error,
    errorUpdatedAt,
    failureCount,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetched,
    isFetchedAfterMount,
    isFetching,
    isFetchingNextPage,
    isIdle,
    isInitialLoading,
    isLoading,
    isLoadingError,
    isPlaceholderData,
    isPreviousData,
    isRefetchError,
    isRefetching,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
  };
};

export default useInfiniteQuery;
