import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import LazyHydration from 'components/LazyHydration';

import { sendEvent } from 'lib/gtag';
import { toFormattedGaEventActionPrefix } from 'lib/string';
import { noop } from 'lib/utils';

import Card from '../Card';

const OfferGridCard = dynamic(() => import('./OfferGridCard'));
const OfferListCard = dynamic(() => import('./OfferListCard'));
const OfferRecommendedCard = dynamic(() => import('./OfferRecommendedCard'));

const OfferCard = ({
  cardTitleAs = 'span',
  gaEventActionPrefix = null,
  gaEventCategory = null,
  hasDropdownMenu = false,
  isListView = false,
  isRecommendedCard = false,
  offerId,
  offerLikes,
  offerTags = [],
  showFooter = true,
  onImageClick = noop,
  onTitleClick = noop,
  ...rest
}) => {
  const onImageClickHandler = useCallback(
    (e) => {
      if (gaEventCategory) {
        sendEvent({
          category: gaEventCategory,
          action: `${toFormattedGaEventActionPrefix(
            gaEventActionPrefix
          )}product_image`,
        });
      }

      onImageClick(e);
    },
    [gaEventCategory, gaEventActionPrefix]
  );

  const onTitleClickHandler = useCallback(
    (e) => {
      if (gaEventCategory) {
        sendEvent({
          category: gaEventCategory,
          action: `${toFormattedGaEventActionPrefix(
            gaEventActionPrefix
          )}product_title`,
        });
      }

      onTitleClick(e);
    },
    [gaEventCategory, gaEventActionPrefix]
  );

  const offerCardProps = {
    cardTitleAs,
    gaEventCategory,
    hasDropdownMenu,
    likesCount: offerLikes,
    offerId,
    offerTags,
    showFooter,
    onImageClick: onImageClickHandler,
    onTitleClick: onTitleClickHandler,
    ...rest,
  };

  if (isRecommendedCard) {
    return <OfferRecommendedCard {...offerCardProps} />;
  }

  return (
    <Card className="h-full">
      {isListView ? (
        <LazyHydration placeholderSize={{ height: 225 }}>
          <OfferListCard {...offerCardProps} />
        </LazyHydration>
      ) : (
        <LazyHydration placeholderSize={{ height: 442 }}>
          <OfferGridCard {...offerCardProps} />
        </LazyHydration>
      )}
    </Card>
  );
};

OfferCard.propTypes = {
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  gaEventCategory: PropTypes.string,
  gaEventActionPrefix: PropTypes.string,
  isListView: PropTypes.bool,
  isRecommendedCard: PropTypes.bool,
  offerId: PropTypes.number.isRequired,
  offerTitle: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerOldPrice: PropTypes.number,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerIsHighlight: PropTypes.bool.isRequired,
  ratings: PropTypes.shape().isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  cardTitleAs: PropTypes.string,
  offerComments: PropTypes.number.isRequired,
  storeDomain: PropTypes.string.isRequired,
  subcategoryId: PropTypes.number,
  subcategoryName: PropTypes.string,
  hasDropdownMenu: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userTypeName: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default memo(OfferCard);
