import { useEffect } from 'react';

const useIntersectionObserver = ({
  target,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
  onIntersect,
}) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach(
          ({ isIntersecting }) => isIntersecting && onIntersect()
        ),
      {
        rootMargin,
        threshold,
      }
    );

    const elem = target && target.current;

    if (!elem) {
      return;
    }

    observer.observe(elem);

    return () => {
      observer.unobserve(elem);
    };
  }, [target.current, enabled]);
};

export default useIntersectionObserver;
